.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.blink {
	animation: flicker 2s infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

@keyframes flicker {
	0% {
		opacity: 0;
	}

	9% {
		opacity: 0;
	}

	10% {
		opacity: .5;
	}

	13% {
		opacity: 0;
	}

	20% {
		opacity: .5;
	}

	25% {
		opacity: 1;
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.carousel-base {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	outline: none;
	position: relative;
}

.item-provider {
	overflow: hidden;
	width: 100%;
	cursor: pointer;
}

.item-container img {
	user-select: none;
	-webkit-user-drag: none;
}

.item-tracker {
	height: 100%;
	display: flex;
}

.carousel-arrow {
	z-index: 1;
}

.glow-on-hover {
	position: relative;
	z-index: 0;
}

.glow-on-hover:before {
	content: '';
	background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
	position: absolute;
	top: -2px;
	left: -2px;
	background-size: 400%;
	z-index: -1;
	filter: blur(5px);
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	animation: glowing 20s linear infinite;
	opacity: 0;
	transition: opacity .3s ease-in-out;
	border-radius: 10px;
}

.glow-on-hover:active:after {
	background: transparent;
}

.glow-on-hover:hover:before {
	opacity: 1;
}

.glow-on-hover:after {
	z-index: -1;
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	/* background: #111; */
	left: 0;
	top: 0;
	border-radius: 10px;
}

@keyframes glowing {
	0% {
		background-position: 0 0;
	}

	50% {
		background-position: 400% 0;
	}

	100% {
		background-position: 0 0;
	}
}

.animation span:nth-child(1) {
	position: absolute;
	top: 0;
	right: 0;
	width: 60%;
	height: 3px;
	background: linear-gradient(to right, #EEBE86, #007681);
	animation: animate1 2s linear infinite;
}

@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}

.animation span:nth-child(2) {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 3px;
	background: linear-gradient(to bottom, #EEBE86, #007681);
	animation: animate2 2s linear infinite;
	animation-delay: 1s;
}

@keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(100%);
	}
}

.animation span:nth-child(3) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 0px;
	background: linear-gradient(to left, #EEBE86, #007681);
	animation: animate3 2s linear infinite;
}

@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(-100%);
	}
}

.animation span:nth-child(4) {
	position: absolute;
	top: 0;
	left: 0;
	height: 60%;
	width: 3px;
	background: linear-gradient(to top, #EEBE86, #007681);
	animation: animate4 2s linear infinite;
	animation-delay: 1s;
}

@keyframes animate4 {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(-100%);
	}
}

.card {
	background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #00bbcc);
}

.card:hover {
	transform: scale(1.05);
	background-image: linear-gradient(to top, rgba(226, 209, 209, 0), rgb(134, 215, 240));
}

.noti {
	cursor: pointer;
}

.noti:hover {
	transform: scale(1.02);
}

.gamecard {
	cursor: pointer;
}

.gamecard:hover {
	transform: scale(1.02);
}

.grad {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 3s ease infinite;
	height: '100%';
}

.up-arrow {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid green;
}


.down-arrow {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid red;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}