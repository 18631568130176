/* html,
body,
#root,
#root div:only-child,
.gantt-container {
    height: 100%;
} */

.gantt-container {
    height: calc(100vh - 100px);
} 

.project{
    color:#4CAF50!important;
}

.gantt_tree_content {
    overflow:hidden;
    text-overflow: ellipsis;
}